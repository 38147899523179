import { Box, Container, CssBaseline, useTheme } from '@mui/material'
import * as React from 'react'
import NavBar from '../Navbar'
import ApplicationPageErrorBoundary from './ApplicationPageErrorBoundary'

const ApplicationPage = (props) => {

    const theme = useTheme()
    const { component: Component, ...rest } = props

    return (
        <React.Fragment>
            <Box sx={{display: 'flex', flexDirection:'column', height: '100%'}}>
                <ApplicationPageErrorBoundary>
                    <CssBaseline />
                    <NavBar />
                    <Container sx={{height: '100%', width: '100%', mt: 24, mb: 2, background: 'white'}} maxWidth={props.fullPageWidth ? false: 'lg'}>
                        <Component {...rest} />
                    </Container>
                </ApplicationPageErrorBoundary>
            </Box>
        </React.Fragment>
    )
        
}

export default ApplicationPage