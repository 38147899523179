const { deepOrange, red } = require("@mui/material/colors");

const config = {
    palette: {
      primary: {
        main: "#ff8001",
      },
      secondary: {
        main: deepOrange['A200'],
      },
      error: {
        light: red['A100'],
        main: red['A400'],
        dark: red['A700']
      },
      background: {
        paper: '#fff',
        default: '#ffffff',
        level1: '#fff'
      },
      divider: "rgba(0,0,0,0.12)",
      text: {
        primary: "rgba(0,0,0,0,87)",
        secondary: "rgba(0,0,0,0.6)",
        disabled: "rgba(0,0,0,0.38)"
      },
      mode: 'light',
      contrastThreshold: 3
    },
    typography: {
      htmlFontSize: 16
    },
    spacing: 4,
    components: {
      MuiTypography: {
        defaultProps: {
          color: 'inherit'
        }
      },
      MuiCircularProgress: {
        defaultProps: {
          size: 24,
          color: 'primary',
          disableShrink: true
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: 'filled',
          margin: 'dense',
          color: 'primary',
          fullWidth: true,
          size: 'small'
        }
      },
      MuiSelect: {
        defaultProps: {
          variant: 'filled',
          size: 'small',
          margin: 'dense',
          color: 'primary',
          fullWidth: true
        }
      },
      MuiList: {
        defaultProps: {
          dense: true
        }
      },
      MuiMenuItem: {
        defaultProps: {
          dense: true,
          disableGutters: true
        },
        styleOverrides: {
          root: {
            padding: '0px 8px'
          }
        }
      },
      MuiButton: {
        defaultProps: {
          variant:'contained',
          size: 'small',
          color: 'primary',
          disableElevation: true
        },
        styleOverrides: {
          root: {
            fontWeight: 600
          }
        }
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true
        }
      },
      MuiButtonGroup: {
        defaultProps: {
          size: 'small',
          variant: 'contained',
          disableElevation: true
        }
      },
      MuiCheckbox: {
        defaultProps: {
          size: 'small',
          margin: 'dense'
        }
      },
      MuiFab: {
        defaultProps: {
          size: 'small'
        }
      },
      MuiFormControl: {
        defaultProps: {
          margin: 'dense',
          size: 'small',
          fullWidth: true,
          variant: 'filled'
        }
      },
      MuiFormHelperText: {
        defaultProps: {
          margin: 'dense',
          color: 'error'
        }
      },
      MuiIconButton: {
        defaultProps: {
          size: 'small',
          color: 'primary'
        }
      },
      MuiInputBase: {
        defaultProps: {
          margin: 'dense'
        }
      },
      MuiInputLabel: {
        defaultProps: {
          margin: 'dense'
        }
      },
      MuiRadio: {
        defaultProps: {
          size: 'small'
        }
      },
      MuiSwitch: {
        defaultProps: {
          size: 'small'
        }
      },
      MuiSkeleton: {
        defaultProps: {
          variant: 'rect',
          animation: 'wave'
        }
      },
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        }
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            backdropFilter: 'blur(15px)'
          }
        }
      }
    }
  }

  export default config