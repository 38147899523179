import * as React from 'react'
import { useResetRecoilState } from 'recoil'
import { ApplicationUserState } from '../../../recoil/user/Atom'
import { useNavigate } from 'react-router-dom'

const useLogout = () => {

    /** Clean up the user information on logout */
    const resetUserState = useResetRecoilState(ApplicationUserState)

    let navigate = useNavigate()

    const cleanUpState = () => {

        resetUserState()

        window.localStorage.removeItem('cloudinvent-app-token')

    }

    const logoutUser = () => {

        cleanUpState()
        navigate('/', { replace: true})

    }

    return {
        logoutUser,
        cleanUpState
    }


}

export default useLogout