import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'
import App from './App';
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context'
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import { env } from './env'

const httpLink = new createHttpLink({
  uri: env.REACT_APP_GRAPHQL_API,
  credentials: 'same-origin'
})

const authLink = setContext(({_, headers}) => {

  const token = window.localStorage.getItem('cloudinvent-app-token')

  return {
    headers: {
      ...headers,
      authorization: token ? `${token}`: ``
    }
  }

})

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

const basename = env.REACT_APP_ROUTER_BASENAME

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <RecoilRoot>
        <BrowserRouter basename={basename}>
          <App />
        </BrowserRouter>
      </RecoilRoot>
    </ApolloProvider>
  </React.StrictMode>
);
