import * as React from 'react'
import { useRecoilValue } from 'recoil'
import { ApplicationUserState } from '../../../recoil/user/Atom'
import { RuntimeRouteState, ExpandedMenuState } from '../../../recoil/navigation/Atom'

const useUserStateCheck = (props) => {

    const userState = useRecoilValue(ApplicationUserState)

    const verifyState = () => {

        let valid = true

        if(userState.userId === null){
            valid = false
        }

        return valid

    }

    return {
        verifyState
    }

}

export default useUserStateCheck