import * as React from 'react'
import useTheme from '@mui/material/styles/useTheme'

import Page from './Page'

const PublicRoute = (props) => {

    const theme = useTheme()
    const { component: Component, ...rest } = props
    
    return (
        <React.Fragment>
            <Page component={Component} {...rest} />
        </React.Fragment>
    )

}

export default PublicRoute