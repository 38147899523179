import { Box } from '@mui/material'
import * as React from 'react'

const IndependentPage = (props) => {

    const { component: Component } = props

    return (
        <React.Fragment>
            <Box sx={{height: '100vh', width: '100vw'}}>
                <Component />
            </Box>
        </React.Fragment>
    )

}

export default IndependentPage