import { atom } from 'recoil'
import ROUTES from '../../components/common/Routes'
import FlattenRoutes from '../../utils/navigation/flattenRoutes'

const NavBarOpenState = atom({
    key: 'NavBarOpenState',
    default: false
})

const ActiveRouteIdState = atom({
    key: 'ActiveRouteIdState',
    default: null
})

const ExpandedMenuState = atom({
    key: 'ExpandedMenuState',
    default: () => {

        /**
         * Initialize the expand menu here
         */
        const routes = FlattenRoutes()
        let obj = {}

        routes.forEach((route) => {

            obj[route.routeId] = false

        })

        console.log(`Computing the expand menu default value`)

        return obj

    },
})

const RuntimeRouteState = atom({
    key: 'RuntimeRouteState',
    default: []
})

export {
    NavBarOpenState,
    ActiveRouteIdState,
    ExpandedMenuState,
    RuntimeRouteState
}