import ROUTES from "../../components/common/Routes"

/**
 * Function to convert the nested routes array into a single linear array of routes
 */
const FlattenRoutes = () => {

    const flatList = []

    const ScanData = (data) => {

    for(let i=0;i<data.length;i++){

        if(data[i].nested){
        const { nestedRoutes, ...route } = data[i]
        flatList.push(route)
        ScanData(nestedRoutes)
        } else{
        flatList.push(data[i])
        }

    }

    }

    ScanData(ROUTES)

    return flatList

}

export default FlattenRoutes