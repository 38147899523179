import * as React from 'react'
import { useSetRecoilState } from 'recoil'
import { ApplicationUserState } from '../../../recoil/user/Atom'
import jwtDecode from 'jwt-decode'

const useLogin = () => {

    const setApplicationUserState = useSetRecoilState(ApplicationUserState)

    const postLoginUserInitialization = () => {

        const token = window.localStorage.getItem('cloudinvent-app-token')

        if(token) {

            const decoded = jwtDecode(token)
            setApplicationUserState({...decoded})

        }

    }

    return {
        postLoginUserInitialization
    }


}

export default useLogin