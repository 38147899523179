import * as React from 'react'
import useTheme from '@mui/material/styles/useTheme'
import { gql, userQuery, useMutation, useQuery } from '@apollo/client'
import Page from './Page'
import PageLoading from './PageLoading'
import { Navigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { useRecoilValue } from 'recoil'
import { ApplicationUserState, LoginInitializationState } from '../../recoil/user/Atom'
import UnauthorizedPage from './UnathorizedPage'
import LoginInitializationPage from './LoginInitializationPage'

const UPDATE_INTERVAL = process.env.NODE_ENV === 'production' ? 30000 : 86400000

const VERIFY_TOKEN = gql`

query VerifyAppToken {
    VerifyAppJWTToken
}

`

const PrivateRoute = (props) => {

    const theme = useTheme()
    const { loading, data, error } = useQuery(VERIFY_TOKEN, { fetchPolicy: 'network-only' })
    const userState = useRecoilValue(ApplicationUserState)
    const { component: Component, roles, ...rest } = props

    if(loading){

        return (
            <Page component={PageLoading} />
        )

    }

    if(error){

        window.localStorage.clear()
        return (
            <Navigate to="/" replace={false} />
        )

    }

    window.localStorage.setItem('cloudinvent-app-token', data.VerifyAppJWTToken)

    if(!roles.some(e => userState.role.includes(e))){

        return (
            <React.Fragment>
                <Page component={UnauthorizedPage} fullPageWidth={false} />
            </React.Fragment>
        )

    }

    return (
        <React.Fragment>
            <Page component={Component} {...rest} />
        </React.Fragment>
    )

}

export default PrivateRoute