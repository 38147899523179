import * as React from 'react'
import useTheme  from '@mui/material/styles/useTheme'
import { Box, Container, CssBaseline } from '@mui/material'
import IndependentPage from './Page/IndependentPage'
import ApplicationPage from './Page/ApplicationPage'


const Page = (props) => {

    const theme = useTheme()

    return (
        <React.Fragment>
            <Box sx={{height: '100vh', m:0}}>
                {
                    props.independent ? <IndependentPage {...props} /> : <ApplicationPage {...props} />
                }
            </Box>
        </React.Fragment>
    )

}

export default Page