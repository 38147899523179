import * as React from 'react'
import {  useSetRecoilState } from 'recoil'
import {  LoginInitializationState } from '../../recoil/user/Atom'
import { Box, Grid, LinearProgress } from '@mui/material'
import LogoShortColor from '../../assets/app-logo/LogoFullColor'
import { useLocation, useNavigate } from 'react-router-dom'
import useLogin from '../../hooks/common/user/useLogin'

const initialProgressState = {
    value: 0,
    label: ''
}

const LoginInitializationPage = (props) => {

    const setLoginInitializationState = useSetRecoilState(LoginInitializationState)
    const [progress, setProgress] = React.useState(initialProgressState)
    const { postLoginUserInitialization } = useLogin()
    const currentLocation = useLocation()
    let navigate = useNavigate()   

    React.useEffect(() => {

        (async()=>{

            setProgress({ value: 20, label: 'Setting up user state'})

            postLoginUserInitialization()

            setProgress({ value: 30, label: 'Setting up user environment'})

            try{

                /**
                 * Once the initialization is complete navigate to the authenticated page
                 */

                if(currentLocation.pathname === '/'){

                    /**
                     * If the login initialization is called from the login page then go to the first route
                     * else go load up the existing route
                     */

                    navigate('/dashboard/executive-summary/aws', { replace: false})

                }

                setProgress({ value: 100, label: 'Ready'})

                setLoginInitializationState(false)


            } catch(error){

                console.log(`Error in initialization: ${error}`)

            }
            

        })()

    },[])

    return (
        <React.Fragment>
            <Box sx={{width:'100vw', height: '100vh', overflow:'hidden', background: '#fbe9e7'}}>
                <Grid sx={{height:'100%', width:'100%'}} container alignItems="center" justifyContent="center" spacing={2} direction={"column"}>
                    <Grid item xs={1}>
                        <LogoShortColor style={{width: '20em'}} />
                    </Grid>
                    <Grid sx={{width: '20em', textAlign:'center'}} item xs={1}>
                        <LinearProgress variant='determinate' value={progress.value}  />
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )

}

export default LoginInitializationPage