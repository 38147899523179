import { Grid, Typography } from '@mui/material'
import * as React from 'react'

const UnauthorizedPage = (props) => {

    return (
        <React.Fragment>
            <Grid container alignItems="center" justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="error" align="center">Not Authorized to access this page</Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    )

}

export default UnauthorizedPage