import * as React from 'react'
import jwtDecode from 'jwt-decode'
import { gql, useLazyQuery } from '@apollo/client'

const RENEW_QLIK_TOKEN = gql`

query Query($input: ExternalUserQlikTokenInput) {
  renewQlikToken(input: $input)
}

`

const useQlikTokenExpiryCheck = () => {

    const [renewQlikToken] = useLazyQuery(RENEW_QLIK_TOKEN)

    const checkAndRenewQlikToken = async () => {

        const qlikToken = window.localStorage.getItem('cloudinvent-qlik-token')

        if(qlikToken != null ){

            const decoded = jwtDecode(qlikToken)
            const expiryTime = decoded.exp * 1000, currentTime = Date.now()

            let tokenExpired = expiryTime < currentTime

            if(tokenExpired){

                /**
                 * The Qlik JWT token has expired, new token needs to be renewed
                 */
                try {
                    
                    const renewTokenResult = await renewQlikToken({
                        variables: {
                            input: {
                                token: qlikToken
                            }
                        }
                    })
    
                    const newToken = renewTokenResult.data.renewQlikToken

                    /** Store the renewed token in the local storage */
                    window.localStorage.setItem('cloudinvent-qlik-token', newToken)
                    return true

                } catch (error) {
                    
                    /**
                     * Remove existing token to prevent any misuse
                     */
                    window.localStorage.removeItem('cloudinvent-qlik-token')
                    return false

                }


            }

        }

        return

    }

    return { checkAndRenewQlikToken }

}

export default useQlikTokenExpiryCheck