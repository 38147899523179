import * as React from 'react'

import * as Services from './services'
import * as Dashboard from './dashboard'

const LoginPageContainer = React.lazy(() => import('./login/PageContainer'))
const HomePageContainer = React.lazy(() => import('./home/PageContainer'))
const BudgetPageContainer = React.lazy(() => import('./budget/PageContainer'))
const NotFoundPageContainer = React.lazy(() => import('./not-found/PageContainer'))

export {
    LoginPageContainer,
    HomePageContainer,
    BudgetPageContainer,
    NotFoundPageContainer,
    Services,
    Dashboard
}