import { Grid, Typography } from '@mui/material'
import * as React from 'react'

class ApplicationPageErrorBoundary extends React.Component {

    constructor(props) {

        super(props)
        this.state = { hasError: false }

    }

    static getDerivedStateFromError(error) {

        return { hasError: true }

    }

    render() {

        if(this.state.hasError){

            return (
                <React.Fragment>
                    <Grid sx={{height: '100%'}} container alignItems={"center"} justifyContent="center" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="error" align="center">Error in displaying page</Typography>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )

        }

        return this.props.children

    }

}

export default ApplicationPageErrorBoundary