import * as React from 'react'
import jwtDecode from 'jwt-decode'
import { useLazyQuery, gql } from '@apollo/client'

const VERIFY_TOKEN = gql`

query VerifyAppToken {
    VerifyAppJWTToken
}

`

const useUserAppToken = (props) => {

    const [renewJwtToken] = useLazyQuery(VERIFY_TOKEN)

    const userTokenExist = () => {

        const token = window.localStorage.getItem('cloudinvent-app-token')

        if(token) {

            return true

        } else {

            return false

        }

    }

    const isJwtTokenValid = () => {

        const token = window.localStorage.getItem('cloudinvent-app-token')

        if(!token) return false

        const decoded = jwtDecode(token)
        const expiryTime = decoded.exp * 1000, currentTime = Date.now()

        /** Checking if the current JWT token has expired or not */
        let tokenExpired = expiryTime < currentTime

        if(tokenExpired) {

            /** If the JWT token is expired then it needs to be removed */
            window.localStorage.removeItem('cloudinvent-app-token')
            return false

        } else {

            return true

        }

    }

    const isTokenRenewalRequired = () => {

        const token = window.localStorage.getItem('cloudinvent-app-token')

        if(!token) return false

        const decoded = jwtDecode(token)
        const expiryTime = decoded.exp * 1000, currentTime = Date.now()

        if((expiryTime > currentTime) && ( expiryTime - currentTime < 300000)) {

            /** If the token is valid and less than 5 mins are left then renew token */
            return true

        } else {

            return false

        }

    }

    const renewToken = async () => {

        const tokenValid = isJwtTokenValid()
        const shouldTokenRenew = isTokenRenewalRequired()
        
        if(tokenValid && shouldTokenRenew) {

            /** If the JWT token is still valid, a new token will be renewed */
            const renewTokenResult = await renewJwtToken()

            /** Storing the new JWT token in the local store */
            const newToken = renewTokenResult.data.VerifyAppJWTToken
            window.localStorage.setItem('cloudinvent-app-token', newToken)

        }

        return

    }

    return {
        renewToken,
        isJwtTokenValid,
        userTokenExist
    }

}

export default useUserAppToken