import * as React from 'react'
import Header from './Header'
import SideNav from './SideNav'

const NavBar = (props) => {

    return (
        <React.Fragment>
            <Header />
            <SideNav />
        </React.Fragment>
    )

}

export default NavBar